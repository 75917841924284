<!-- クエスト登録内容確認の各項目用のテンプレート -->
<template>
  <div class="quest-item-card">
    <div class="body">
      <div class="left">
        <slot name="title" />
      </div>
      <div class="right text-left">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmQuestItem'
};
</script>

<style lang="scss" scoped>
.quest-item-card {
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 10px;
  // background: #ffffff;
  border-bottom: solid 1px #dcdcdc;

  .body {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;

    .left {
      text-align: right;
      // display: inline-flex;
      // align-items: center;
      width: 30%;
      padding: 0 20px;
      // border-right: #dcdcdc solid 1px;
    }

    .right {
      display: inline-flex;
      align-items: center;
      width: 70%;
      padding: 0 20px;
      position: relative;
    }

    @media screen and (max-width: 764px) {
      .left {
        width: 100%;
        // border-right: none;
        text-align: left;
        border-bottom: #dcdcdc dashed 1px;
        padding: 5px;
      }

      .right {
        width: 100%;
        padding: 5px;
      }
    }
  }
}

@media screen and (max-width: 764px) {
  .quest-item-card {
    border-bottom: none;
  }
}
</style>
