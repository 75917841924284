<template>
  <div>
    <v-container class="text-center mb-6 mt-10" style="max-width: 1000px">
      <div class="text-start mb-5">クエストを依頼する</div>
      <v-card
        class="inset rounded-xl ms-5 mt-3 mb-12 py-10"
        style="width: 90%"
        :style="styles.app"
      >
        <v-row no-gutters class="px-10">
          <v-col
            cols="12"
            class="mb-3"
          >
            <column-sticky-note class="text-start font-weight-bold">
              <template v-slot:content>
                <div>下記内容でクエストを依頼します。</div>
                <div>
                  問題なければ一番下の「この内容で依頼する」をクリックしてください。
                </div>
                <div class="red--text text--lighten-1">
                  依頼後の内容修正はできないため、必ず依頼内容を確認してください。
                </div>
              </template>
            </column-sticky-note>
          </v-col>

          <v-col cols="12" class="mb-1">
            <confirm-quest-item>
              <template v-slot:title>
                <div class="text-h6 font-weight-bold">カテゴリー</div>
              </template>
              <template v-slot:content>
                <span>{{ categoryName }}</span>
              </template>
            </confirm-quest-item>
          </v-col>

          <v-col cols="12" class="mb-1">
            <confirm-quest-item>
              <template v-slot:title>
                <div class="text-h6 font-weight-bold">タイトル</div>
              </template>
              <template v-slot:content>
                <span>{{ quest.title }}</span>
              </template>
            </confirm-quest-item>
          </v-col>

          <v-col cols="12" class="mb-1">
            <confirm-quest-item>
              <template v-slot:title>
                <span class="text-h6 font-weight-bold">内容</span>
              </template>
              <template v-slot:content>
                <span v-html="nl2br(quest.content)" />
              </template>
            </confirm-quest-item>
          </v-col>

          <v-col cols="12" class="mb-1">
            <confirm-quest-item>
              <template v-slot:title>
                <span class="text-h6 font-weight-bold">応募期限</span>
              </template>
              <template v-slot:content>
                <span>{{ dateFormat(quest.deadline) }}</span>
              </template>
            </confirm-quest-item>
          </v-col>

          <v-col cols="12" class="mb-1">
            <confirm-quest-item>
              <template v-slot:title>
                <span class="text-h6 font-weight-bold">完了期限</span>
              </template>
              <template v-slot:content>
                <span>{{ dateFormat(quest.delivery) }}</span>
              </template>
            </confirm-quest-item>
          </v-col>

          <v-col cols="12">
            <confirm-quest-item>
              <template v-slot:title>
                <span class="text-h6 font-weight-bold">報酬</span>
              </template>
              <template v-slot:content>
                <span class="text-h5 red--text text--lighten-1 font-weight-bold">
                  {{ intcomma(quest.reward) }}
                </span>
                <span class="red--text text--lighten-1 font-weight-bold ml-1">
                  ポイント
                </span>
              </template>
            </confirm-quest-item>
          </v-col>
        </v-row>

        <v-row class="mb-6 sm-6" justify="center">
          <v-col md="auto">
            <base-button
              :class-name="'white--text py-6'"
              :color="'blue-grey lighten-2'"
              :width="'180'"
              @click="onBack"
            >
              <template v-slot:text>修正する</template>
            </base-button>
          </v-col>
          <v-col md="auto">
            <base-button
              :class-name="'white--text py-6'"
              :color="'primary'"
              :width="'180'"
              @click="onRegistration"
            >
              <template v-slot:text>この内容で依頼する</template>
            </base-button>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import BaseButton from '@/components/atoms/BaseButton';
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import ColumnStickyNote from '@/components/organisms/ColumnStickyNote';
import ConfirmQuestItem from '@/components/organisms/ConfirmQuestItem';
import { quest as blankQuest } from '@/store/models/quest';
import { isEmpty } from 'lodash-es';

export default {
  name: 'CreateConfirm',
  components: {
    BaseButton,
    ColumnStickyNote,
    ConfirmQuestItem
  },
  mixins: [AppMethodsMixin, AppStyleMixin],
  computed: {
    quest() {
      const currentQuest = this.$store.getters['quests/getCurrentQuest'];
      return isEmpty(currentQuest) ? Object.assign({}, blankQuest) : currentQuest;
    },
    categoryName() {
      const questCategories = this.$store.getters['questCategories/getCategories'];
      for (let i = 0; i <= questCategories.length; i++) {
        if (questCategories[i] && questCategories[i].id === this.quest.category) {
          return questCategories[i].name;
        }
      }
      return null;
    }
  },
  methods: {
    /** 依頼画面に戻る */
    onBack() {
      this.$router.go(-1);
    },
    /** クエスト登録 */
    async onRegistration() {
      // クエスト編集の場合
      if (this.$route.params.questId) {
        await this.$store.dispatch('quests/update', { questId: this.$route.params.questId, quest: this.quest });

      // 所属部署の場合
      } else {
        await this.$store.dispatch('quests/create', this.quest);
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
