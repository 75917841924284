<!-- 横向きの付箋 -->
<template>
  <div class="column-sticky-note">
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: 'ColumnStickyNote'
};
</script>

<style lang="scss" scoped>
.column-sticky-note {
  width: 100%;
  padding: 1em 1.5em;
  // transform: rotate(1deg);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0.25rem 0px 0.25rem;
  background-image:
    linear-gradient(90deg, hsla(0, 0%, 45%, .1) 2rem, hsla(0, 100%, 100%, 0) 2.5rem),
    linear-gradient(90deg, hsla(200, 100%, 85%, 1), hsla(200, 100%, 85%, 1));
  font-size: 1.125rem;
  line-height: 1.8;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
